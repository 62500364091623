import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material"
import Markdown from "react-markdown"
import remarkMath from "remark-math"
import rehypeKatex from "rehype-katex"
import css from "./ModalDetallesConsultaBarbeibot.module.css"
import "katex/dist/katex.min.css"
import { dateTimeToString } from "../../utils/date"
import { useEffect, useState } from "react"

const ModalDetallesConsultaBarbeibot = ({ open, consulta, onClose }) => {

  const [isQuestionExpanded, setIsQuestionExpanded] = useState(true)
  const [isAnswerExpanded, setIsAnswerExpanded] = useState(true)

  useEffect(() => {
    setIsQuestionExpanded(true)
    setIsAnswerExpanded(true)
  }, [open])
  
  const formattedContent = consulta?.respuesta
    ?.replaceAll("\\[", "$$")
    .replaceAll("\\]", "$$")
    .replaceAll("\\(", "$")
    .replaceAll("\\)", "$")

  if (!consulta) return null

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth="md">
      <DialogTitle>
        Pregunta del {dateTimeToString(consulta.fecha)}
      </DialogTitle>
      <DialogContent className={css.main}>
        <Accordion 
          expanded={isQuestionExpanded} 
          onChange={() => setIsQuestionExpanded(!isQuestionExpanded)}
        >
          <AccordionSummary
            expandIcon={<i className='material-icons'>expand_more</i>}
          >
            <div className={css.label}>
              <Typography className={css.title} variant="h2">Pregunta</Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={css.texto}>
              {consulta.pregunta}
            </Typography>
            {consulta?.urlImagen && (
              <div className={css.imgContenedor}>
                <img
                  src={consulta.urlImagen}
                  alt="Imagen de la consulta"
                  className={css.image}
                />
              </div>
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion 
          expanded={isAnswerExpanded} 
          onChange={() => setIsAnswerExpanded(!isAnswerExpanded)}
        >
          <AccordionSummary
            expandIcon={<i className='material-icons'>expand_more</i>}
          >
            <div className={css.label}>
              <Typography className={css.title} variant="h2">Respuesta</Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className={css.markdownContainer}>
              <Markdown remarkPlugins={[remarkMath]} rehypePlugins={[rehypeKatex]}>
                {formattedContent}
              </Markdown>
            </div>
          </AccordionDetails>
        </Accordion>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={onClose}>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalDetallesConsultaBarbeibot
