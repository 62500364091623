import { useState } from 'react'
import { Button, TextField, Typography } from '@mui/material'
import css from './BarbeibotQuestion.module.css'

const BarbeibotQuestion = ({ onSubmit }) => {

  const [question, setQuestion] = useState('')

  const handleSubmit = () => {
    if (question.trim() === '') return
    onSubmit(question)
    setQuestion('')
  }

  return (
    <div className={css.main}>
      <Typography variant="h6" gutterBottom>
        Pregunta cualquier cosa a Barbeibot
      </Typography>
      <TextField
        value={question}
        onChange={(e) => setQuestion(e.target.value)}
        className={css.textarea}
        label=""
        placeholder="Escribe tu pregunta"
        multiline
        minRows={4}
        maxRows={8}
        helperText="Recuerda que es una herramienta educativa, haz preguntas relacionadas con mundoestudiante"
      />
      <Button className={css.button} onClick={handleSubmit} variant="contained" color="primary">
        Preguntar
      </Button>
    </div>
  )

}

export default BarbeibotQuestion