import { Badge, Collapse, IconButton, Link, TableCell, TableRow, TextField } from "@mui/material"
import EstadoAsistencia from "../../../../components/EstadoAsistencia/EstadoAsistencia"
import { isBlank } from "../../../../utils/string"
import { isClassfy } from "../../../../utils/academia"
import useResponsive from "../../../../hooks/useResponsive"
import css from './PruebaRow.module.css'

const PruebaRow = ({ asistencia, centro, isOpen, onOpen, onChange, onClick })=> {

  const { size, isScreenBiggerThan } = useResponsive()

  const getColspan = ()=> {
    if (size === 'xl' || size === 'lg') return 6
    if (size === 'md') return 5
    return 4
  }
  
  return (
    <>
      <TableRow className={css.pruebaRow}>
        <TableCell className={css.mainRowCell}>
          <div className={css.alumno}>
            {isClassfy(asistencia.centro) && (
              <i className='material-icons'>laptop</i>
            )}
            <Link
              className={css.alumnoLink}
              href={`/leads/${asistencia.feedbackId}/ficha`}
            >
              {asistencia.feedbackNombre}
            </Link>
          </div>
        </TableCell>
        <TableCell 
          className={css.mainRowCell}
          data-visibility={isScreenBiggerThan('sm') ? 'visible' : 'invisible'} 
        >
          {asistencia.asignatura}
        </TableCell>
        <TableCell 
          className={css.mainRowCell}
          data-visibility={isScreenBiggerThan('md') ? 'visible' : 'invisible'} 
        >
          {asistencia.clase}
        </TableCell>
        <TableCell className={css.mainRowCell} align="center">
          <EstadoAsistencia
            asistencia={asistencia}
            centro={centro}
            onClick={(asistenciaAlumno)=> onChange(asistenciaAlumno, asistencia.feedbackId)}
          />
        </TableCell>
        <TableCell className={css.mainRowCell} align="center">
          <IconButton
            color='secondary'
            size="small"
            onClick={()=> onClick(asistencia, 'valoracion')}
          >
            <i className='material-icons'>edit_note</i>
          </IconButton>
        </TableCell>
        <TableCell className={css.mainRowCell}>
          <IconButton
            size="small"
            onClick={onOpen}
          >
            <Badge color="primary" variant="dot" invisible={isBlank(asistencia.observaciones)}>
              <i className='material-icons'>
                {isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
              </i>
            </Badge>
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow className={css.pruebaRow}>
        <TableCell className={css.additionalRow} colSpan={getColspan()}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <p className={css.extraInfo}>
              <i className='material-icons'>school</i>
              <span>
                Prueba de <strong>{asistencia.asignatura}</strong> de nivel <strong>{asistencia.nivel}</strong> ({asistencia.tipo})
              </span>
            </p>
            {asistencia.centro !== centro && (
              <p className={css.extraInfo}>
                <i className='material-icons'>warning</i>
                <span>
                  Está dando clase en <strong>{asistencia.centro}</strong>
                </span>
              </p> 
            )}
            {asistencia.feedbackTelefono && (
              <p className={css.extraInfo}>
                <i className='material-icons'>phone</i>
                <span>
                  Teléfono de contacto: <strong>{asistencia.feedbackTelefono}</strong>
                </span>
              </p> 
            )}
            {asistencia.observaciones && (
              <div className={css.observaciones}>
                <TextField
                  name='observaciones'
                  label='Observaciones'
                  variant='outlined'
                  margin='dense'
                  value={asistencia.observaciones}
                  maxRows={8}
                  multiline
                  fullWidth
                />
              </div>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default PruebaRow