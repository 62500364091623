import { useState } from "react"
import { useMutation } from "@tanstack/react-query"
import { Button } from "@mui/material"
import avatar from './barbeibot.png'
import { preguntaImagen } from "../../../utils/api/barbeibot"
import { useNotification } from "../../../context/NotificationManager/NotificationManager"
import { LoadingSpinner } from "../../../components/PageLoading/PageLoading"
import MarkdownContent from "../../../components/MarkdownContent/MarkdownContent"
import BarbeibotImage from "../../../components/AIChat/BarbeibotImage"
import css from './Imagen.module.css'

const Imagen = ()=> {

  const notification = useNotification()

  const [respuesta, setRespuesta] = useState(null)
  
  const { isPending: isAsking, mutate: ask } = useMutation({
    mutationFn: preguntaImagen,
    onSuccess: (respuesta)=> {
      setRespuesta(respuesta)
    },
    onError: err=> {
      notification.error({ title: 'Error al preguntar al Barbeibot', content: err })
    }
  })

  const handleQuestion = ({ question, file }) => {
    if (isAsking) return
    if (!file) {
      notification.error({ title: 'Error al preguntar al Barbeibot', content: 'La imagen seleccionada no se ha podido procesar' })
      return
    }
    return ask({ pregunta: question, image: file })
  }
  
  const handleReset = ()=> {
    setRespuesta(null)
  }

  const isPregunta = !respuesta && !isAsking
  const isRespuesta = respuesta && respuesta.respuesta 
  
  return (
    <>
      {isPregunta  && (
        <div className={css.question}>
          <img className={css.avatar} src={avatar} alt="Barbeibot" />
          <BarbeibotImage onSubmit={handleQuestion} />
        </div>
      )}
      {isAsking && (
        <div className={css.loading}>
          <div className={css.thought}>
            <div className={css.bubble}>
              A ver, deja que vea esto...
            </div>
            <img className={css.avatar} src={avatar} alt="Barbeibot" />
          </div>
          <LoadingSpinner className={css.spinner} />
        </div>
      )}
      {isRespuesta && (
        <div className={css.answer}>
          <div className={css.response}>
            <MarkdownContent content={respuesta.respuesta} />
          </div>
          <div className={css.answerActions}>
            <div className={css.buttons}>
              <Button variant="outlined" color="secondary" onClick={handleReset}>Analizar nueva imagen</Button>
            </div>
            <img className={css.responseAvatar} src={avatar} alt="Barbeibot" />
          </div>
        </div>
      )}
    </>
  )
}

export default Imagen