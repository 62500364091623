import { Button, Dialog, DialogActions, DialogContent } from "@mui/material"
import css from "./ModalImagenBarbeibot.module.css"

const ModalImagenBarbeibot = ({ open, onClose, onDelete, url }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogContent>
      <img className={css.image} src={url} alt="Imagen para análisis con barbeibot" />
    </DialogContent>
    <DialogActions>
      <Button onClick={onDelete} color="error">
        Borrar imagen
      </Button>
      <Button onClick={onClose} color="secondary">
        Cerrar
      </Button>
    </DialogActions>
  </Dialog>
)

export default ModalImagenBarbeibot
