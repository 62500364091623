import { useState } from "react"
import { useQuery } from "@tanstack/react-query"
import { IconButton } from "@mui/material"
import PageLoading from "../../../components/PageLoading/PageLoading"
import TableHeader from "../../../components/TableHeader/TableHeader"
import InformationTable from "../../../components/Table/InformationTable"
import MesRecienteSelector from "../../../components/form/MesRecienteSelector/MesRecienteSelector"
import { useNotification } from "../../../context/NotificationManager/NotificationManager"
import { getConsultaList } from "../../../utils/api/barbeibot"
import { dateFromMonthString, dateTimeToString, dateToMonth, monthToString } from "../../../utils/date"
import ModalDetallesConsultaBarbeibot from "../../../modals/ModalDetallesConsultaBarbeibot/ModalDetallesConsultaBarbeibot"
import css from "./Historial.module.css"
import { limitLength } from "../../../utils/string"


const Historial = () => {
  const notification = useNotification()
  const [mes, setMes] = useState(monthToString(new Date()))
  const [selectedConsulta, setSelectedConsulta] = useState(null)
  const [isDetallesConsultaOpen, setIsDetallesConsultaOpen] = useState(false)

  const { isLoading, data: consultaList = [] } = useQuery({
    queryKey: ["interno", "ia", "consulta-barbeibot", "list", mes],
    enabled: !!mes,
    queryFn: () => getConsultaList(mes)
      .then((response) => {
        const sortedData = response.sort((a, b) => b.fecha - a.fecha)
        return sortedData
      })
      .catch((err) => {
        notification.error({ title: "Error al recuperar el historial de las consultas", content: err })
        return []
      }),
  })

  const handleOpenModal = (consulta) => {
    setSelectedConsulta(consulta)
    setIsDetallesConsultaOpen(true)
  }

  return (
    <PageLoading isLoading={isLoading}>
      <TableHeader
        showSearch={false}
        actions={
          <div className={css.actions}>
            <MesRecienteSelector
              className={css.mes}
              name="mes"
              label="Mes"
              forwardOptions={0}
              backwardOptions={5}
              value={mes}
              onChange={(e) => setMes(e.target.value)}
            />
          </div>
        }
        title={`${consultaList.length} consultas realizadas en ${dateToMonth(dateFromMonthString(mes))}`}
      />
      <InformationTable
        details={[
          { title: "Pregunta", key: "pregunta"},
          { title: "Fecha y hora", key: "fecha", sortDisabled: true },
          { title: "Modelo usado", key: "modeloUsado", sortDisabled: true },
          { title: "Opciones", key: "opciones", align: "center", sortDisabled: true },
        ]}
        data={consultaList.map((consulta) => ({
          pregunta: (
            <div className={css.preguntaTexto}>
              {limitLength(consulta.pregunta, 150)}
            </div>
          ),
          fecha: dateTimeToString(consulta.fecha) || "--",
          modeloUsado: consulta.modelo || "--",
          opciones: (
            <IconButton
              size="small"
              color="secondary"
              onClick={() => handleOpenModal(consulta)}
            >
              <i className="material-icons">remove_red_eye</i>
            </IconButton>
          ),
        }))}
        isFetching={isLoading}
      />
      <ModalDetallesConsultaBarbeibot
        consulta={selectedConsulta}
        open={isDetallesConsultaOpen}
        onClose={() => setIsDetallesConsultaOpen(false)}
      />
    </PageLoading>
  )
}

export default Historial
