import { useRef, useState } from "react"
import { Button, Card, TextField, Typography } from '@mui/material'
import css from "./BarbeibotImage.module.css"
import ModalImagenBarbeibot from "../../modals/ModalImagenBarbeibot/ModalImagenBarbeibot"

const BarbeibotImage = ({ onSubmit }) => {

  const [question, setQuestion] = useState('')
  const [preview, setPreview] = useState(null)

  const [isDragging, setIsDragging] = useState(false)
  const [showImage, setShowImage] = useState(false)
  const [isProcessing, setProcessing] = useState(false)

  const fileInputRef = useRef(null)
  const cameraInputRef = useRef(null)

  const handleDragOver = (e) => {
    e.preventDefault()
    setIsDragging(true)
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
    setIsDragging(false)
  }

  const handleDrop = (e) => {
    e.preventDefault()
    setIsDragging(false)
    const file = e.dataTransfer.files[0]
    handleFile(file)
  }

  const handleFileInput = (e) => {
    const file = e.target.files?.[0]
    if (!file) return
    handleFile(file)
  }

  const handleFile = (file) => {
    if (!file) return
    if (!file.type.startsWith('image/')) return
    const reader = new FileReader()
    reader.onloadend = () => { setPreview(reader.result) }
    reader.readAsDataURL(file)
  }

  const handleImageClick = () => {
    setShowImage(true)
  }

  const handleCameraClick = (e) => {
    e.stopPropagation()
    if (cameraInputRef.current) {
      cameraInputRef.current.click()
    }
  }

  const handleGalleryClick = (e) => {
    e.stopPropagation()
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleRemoveFile = () => {
    setShowImage(false)
    setPreview(null)
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
    if (cameraInputRef.current) {
      cameraInputRef.current.value = ''
    }
  }

  const handleSubmit = async () => {
    setProcessing(true)
    const response = await fetch(preview)
    const blob = await response.blob()
    onSubmit({ question, file: blob })
    setProcessing(false)
  }

  return (
    <div className={css.main}>
      <Typography variant="h6" gutterBottom>
        Analiza cualquier imagen con el Barbeibot
      </Typography>
      <div className={css.fields}>
        <div className={css.imageField}>
          <Card 
            data-drop={isDragging ? 'active' : 'inactive'}
            className={css.card}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onClick={preview ? handleImageClick : null}
          >
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileInput}
            accept="image/*"
            className={css.input}
          />
          <input
            type="file"
            ref={cameraInputRef}
            onChange={handleFileInput}
            accept="image/*"
            capture="environment"
            className={css.input}
          />
            {preview ? (
              <div className={css.previewContainer}>
                <img
                  src={preview}
                  alt="Preview"
                  className={css.preview}
                />
              </div>
            ) : (
              <>
                <Typography className={css.title} variant="body1">
                  Selecciona una imagen para analizar
                </Typography>
                <div className={css.buttons}>
                  <Button
                    variant="outlined"
                    startIcon={<i className='material-icons'>camera_alt</i>}
                    onClick={handleCameraClick}
                  >
                    Cámara
                  </Button>
                  <Button
                    variant="outlined"
                    startIcon={<i className='material-icons'>photo_library</i>}
                    onClick={handleGalleryClick}
                  >
                    Galería
                  </Button>
                </div>
              </>
            )}
          </Card>
        </div>
        <div className={css.textField}>
          <TextField
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            className={css.textarea}
            label=""
            placeholder="Escribe tu pregunta"
            multiline
            minRows={5}
            maxRows={8}
          />
        </div>
      </div>
      <Button 
        className={css.button} 
        disabled={isProcessing || !question || !preview}
        onClick={handleSubmit} 
        variant="contained" 
        color="primary"
      >
        Preguntar
      </Button>
      <ModalImagenBarbeibot
        open={showImage}
        onClose={()=> setShowImage(false)}
        onDelete={handleRemoveFile}
        url={preview}
      />
    </div>
  )
}

export default BarbeibotImage
